// If you don't want to use TypeScript you can delete this file!
import React from 'react'
import { PageProps, graphql } from 'gatsby'

import Layout from '../components/Layout'
import { GoodsQuery } from '../../types/graphql-types'
import { Title } from '../components/Typography'
import { renderWithToc } from '../utils/render'
import { Box } from '@material-ui/core'
import { Options } from '@jsdevtools/rehype-toc'
import { Node } from 'unist'

const olToUl = (node: Node): Node => ({
  ...node,
  tagName: node.tagName === 'ol' ? 'ul' : node.tagName,
  children: (node.children as Node[] | undefined)?.map(olToUl),
})

const tocOptions: Options = {
  headings: ['h1', 'h2'],
  customizeTOC: toc => olToUl(toc),
}

const Goods: React.FC<PageProps<GoodsQuery>> = ({ data }) => (
  <Layout path="Goods" title="Goods">
    <Title>Goods</Title>
    <Box>{renderWithToc(data.microcmsIndex?.goods, tocOptions)}</Box>
  </Layout>
)

export default Goods

export const query = graphql`
  query Goods {
    microcmsIndex {
      goods
    }
  }
`
